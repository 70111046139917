import React from "react";
import { Button, PropertyFilterProps } from "@cloudscape-design/components";
import { Storage } from "aws-amplify";
import { formatDate, formatDateWithTime } from "../../data/helpers/dates";
import { IGetBulk } from "../../data/interfaces/IBulk";
import { BULK_JOB_STATUS, ENTITY_TYPE } from "../../data/constants/common";

const StatusColors = {
  Succeeded: "#059D1E",
  Failed: "#a30d0d",
  "System Error": "#E07941",
} as const;

/**
 * Creates an S3 object key path to be used to save uploaded file
 * @param activeTabId Obtains from ENTITY_TYPES
 * @param bulkJobId Of type BulkJobData
 * @param businessGroupSlug Required if it is not a bulk users upload
 * @returns An S3 bucket object key path. 
 * e.g: upload/2024/2/16/9a7f372e-15e3-410e-8fdd-2f788bbc1a01/CLEAN_bulk_users2.csv
 */
export const getObjectKeyPath = (
  activeTabId: string,
  bulkJobId: string,
  businessGroupSlug: string | undefined,
): string => {
  const date: Date = new Date();

  const path = activeTabId === ENTITY_TYPE.USERS
    ? `upload/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}/${bulkJobId}/`
    : `upload/${businessGroupSlug}/${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}/${bulkJobId}/`;


  return path;
};

const getDetailLogFileName = (lastUpdatedDate: string, status: string) => {
  const filePrefix = status === BULK_JOB_STATUS.SUCCEEDED ? "success" : "error";
  return `${filePrefix}_${formatDateWithTime(lastUpdatedDate)}.csv`;
};

const downloadBlob = async (
  blob: any,
  lastUpdatedDate: string,
  status: string
) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = getDetailLogFileName(lastUpdatedDate, status);
  return a;
};

const downloadFileFromS3Bucket = async (
  lastUpdatedDate: string,
  status: string,
  objectURL?: string
) => {
  if (objectURL) {
    //object url of the form: https://mode-tacs-bulk-processed-bucket-dev-sandbox.s3.us-west-2.amazonaws.com/public/staging/[jobid]/FAILED_CAMPAIGNS.csv
    const fileName = objectURL.split("public/")[1];
    const bucketName = objectURL.split(".")[0].split("https://")[1];

    const result = await Storage.get(fileName, {
      download: true,
      bucket: bucketName,
    });
    return downloadBlob(result.Body, lastUpdatedDate, status);
  }
};

export const RAW_COLUMNS = [
  {
    id: "jobId",
    header: "Job ID",
    cell: (e: IGetBulk) => e.jobId,
    sortingField: "jobid",
    minWidth: "180px",
  },
  {
    id: "createdBy",
    header: "Created by",
    cell: (e: IGetBulk) => e.metadata?.createdBy,
    sortingField: "createdBy",
    minWidth: "165px",
  },
  {
    id: "dateStarted",
    header: "Date started",
    cell: (e: IGetBulk) => formatDate(e.metadata?.creationDate),
    sortingField: "dateStarted",
    minWidth: "165px",
  },
  {
    id: "status",
    header: "Job status",
    cell: (e: IGetBulk) => (
      <span
        style={{ color: StatusColors[e.status as keyof typeof StatusColors] }}
      >
        {e.status}
      </span>
    ),
    sortingField: "status",
    minWidth: "165px",
  },
  {
    id: "detailLog",
    header: "Detail log",
    cell: (e: IGetBulk) => {
      // Location from the API should look something like:
      // https://mode-tacs-bulk-processed-bucket-dev-sandbox3.s3.us-west-2.amazonaws.com/staging/89950793-d34f-3fab-a825-4fae5c2f8fb8/VALIDATION_ERRORS.csv
      // The key we need to retrieve this file is the end of the string starting with staging/
      const locations = e?.locations;
      const lastUpdatedDate = e?.metadata?.lastUpdatedDate;
      if (locations?.failed || locations?.succeeded) {
        const handleClick = async () => {
          const downloadLinkPromise = downloadFileFromS3Bucket(
            lastUpdatedDate,
            e.status,
            e.status === BULK_JOB_STATUS.SUCCEEDED
              ? locations?.succeeded
              : locations?.failed
          );
          const downloadLink = await downloadLinkPromise;
          downloadLink?.click();
        };

        return (
          <Button onClick={() => handleClick()} variant="link">
            {getDetailLogFileName(lastUpdatedDate, e.status)}
          </Button>
        );
      }
    },
    sortingField: "detailLog",
    minWidth: "165px",
  },
];

export const TABLE_VISIBLE_COLUMNS = [
  "jobId",
  "createdBy",
  "dateStarted",
  "status",
  "detailLog",
];

export const PREFERENCES_VISIBLE_COLUMNS = [
  "jobId",
  "createdBy",
  "dateStarted",
  "status",
  "detailLog",
];

export const PREFERENCES_VISIBLE_CONTENT = [
  {
    id: "jobId",
    label: "Job ID",
    editable: false,
  },
  {
    id: "createdBy",
    label: "Created by",
  },
  {
    id: "dateStarted",
    label: "Date started",
  },
  {
    id: "status",
    label: "Job status",
  },
  {
    id: "detailLog",
    label: "Detail log",
  },
];

export const FILTERING_PROPERTIES: PropertyFilterProps.FilteringProperty[] = [
  {
    key: "createdBy",
    operators: ["="],
    propertyLabel: "Created by",
    groupValuesLabel: "Created by values",
  },
  {
    key: "status",
    operators: ["="],
    propertyLabel: "Job status",
    groupValuesLabel: "Job status values",
  },
];
