import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "@cloudscape-design/global-styles/index.css";
import "./index.css";
import { AuthContextProvider } from "./context/AuthContext";
import App from "./App";
import MessagesProvider from "./context/MessagingContext";
import { Amplify } from "aws-amplify";
import { AUTH_CONFIG } from "./data/config/authConfig";
import { S3_BULK_UPLOAD_CONFIG } from "./data/constants/s3BulkUploadConfig";
import { ANALYTICS_CONFIG } from "./data/config/analyticsConfig";
import { initiateRumClient } from "./data/helpers/utils";
import { TutorialProvider } from "./components/tutorials/store";
import { TaCSTutorial } from "./components/tutorials/index";
import { RepositoryProvider } from "./context/RepositoryContext";

Amplify.configure({
  ...AUTH_CONFIG,
  ...S3_BULK_UPLOAD_CONFIG,
  ...ANALYTICS_CONFIG,
});

initiateRumClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <RepositoryProvider>
        <BrowserRouter>
          <MessagesProvider>
            <TutorialProvider>
              <TaCSTutorial>
                <App />
              </TaCSTutorial>
            </TutorialProvider>
          </MessagesProvider>
        </BrowserRouter>
      </RepositoryProvider>
    </AuthContextProvider>
  </React.StrictMode>
);
