import {
  ButtonProps,
  CollectionPreferencesProps,
  NonCancelableCustomEvent,
  PropertyFilterProps,
  TableProps,
} from "@cloudscape-design/components";
import React, { useContext, useEffect, useState } from "react";
import TaCSTableCloudscape from "../../../components/table/TaCSTableCloudscape";
import { AuthContext } from "../../../context/AuthContext";
import {
  BU_FILTERING_PROPERTIES,
  BUSINESS_UNITS_TABLE_COLUMNS,
  PREFERRED_VISIBLE_COLUMNS_BU,
  VISIBLE_CONTENT_OPTION_PREFERENCE_BU,
} from "../config";
import { useNavigate } from "react-router-dom";
import {
  ADMIN_ENTITY_TYPE,
  BUSINESS_ROUTE_PATHS,
  ITEM_UNIQUE_ID,
} from "../../../data/constants/common";
import { getTablePreferencesFromLocalStorage, handleSavedSearchFilters } from "../../../data/config/commonDashboardConfig";
import { BUSINESS_PROPERTY } from "../Interface";
import { IBusinessGroup } from "../../../data/interfaces/IUser";
import { DropdownStatusProps } from "@cloudscape-design/components/internal/components/dropdown-status";
import { RepositoryContext } from "../../../context/RepositoryContext";
import { getSavedSearchFiltersLocalStorage } from "../../../components/table/TableUtils";

type Props = {
  getIdToken: () => string;
  selectedBG: IBusinessGroup | null;
};

export const BusinessUnits: React.FC<Props> = ({ getIdToken, selectedBG }) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [loadingTableData, setLoadingTableData] = useState<boolean>(true);
  const [tableData, setTableData] = useState<any>();
  const [tablePreferences, setTablePreferences] =
    useState<CollectionPreferencesProps.Preferences>(() => {
      return getTablePreferencesFromLocalStorage(
        "businessUnits",
        PREFERRED_VISIBLE_COLUMNS_BU
      );
    });

  const { adminRepo } = useContext(RepositoryContext);
  const navigate = useNavigate();

  const [sortingDescending, setSortingDescending] = useState<
    boolean | undefined
  >(true);
  const [sortingColumn, setSortingColumn] =
    useState<TableProps.SortingColumn<any>>();
  const [searchQuery, setSearchQuery] = useState<PropertyFilterProps.Query>(
    getSavedSearchFiltersLocalStorage(ADMIN_ENTITY_TYPE.BUSINESS_UNITS, true)
  );

  const [filteringStatusType, setFilteringStatusType] =
    useState<DropdownStatusProps.StatusType>("finished");
  const [filteringOptions, setFilteringOptions] = useState<
    PropertyFilterProps.FilteringOption[]
  >([]);

  const { isLoading, data, serverError, refresh } =
    adminRepo.searchBusinessUnits(
      searchQuery,
      tablePreferences.pageSize as number,
      currentPage,
      sortingColumn?.sortingField,
      sortingDescending
    );

  useEffect(() => {
    setTableData(data);
    setLoadingTableData(false);
  }, [isLoading, currentPage, tablePreferences]);

  useEffect(() => {
    handleSavedSearchFilters({
      query: searchQuery,
      entityType: ADMIN_ENTITY_TYPE.BUSINESS_UNITS,
      isAdmin: true
    });
  }, [searchQuery]);


  const handleOnCreate = (
    event: CustomEvent<ButtonProps.ClickDetail>
  ): void => {
    event.preventDefault();
    navigate(
      `${BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT}${BUSINESS_ROUTE_PATHS.BUSINESS_UNITS}${BUSINESS_ROUTE_PATHS.CREATE}`
    );
  };

  const handleBUTableActions = (): void => {
    navigate(
      `${BUSINESS_ROUTE_PATHS.BUSINESS_MANAGEMENT}${BUSINESS_ROUTE_PATHS.BUSINESS_UNITS}${BUSINESS_ROUTE_PATHS.EDIT}`,
      {
        state: {
          ...selectedItems[0],
        },
      }
    );
  };

  const handleSearchChange = (query: PropertyFilterProps.Query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  const handleSearchOnLoadItems = async (
    event: NonCancelableCustomEvent<PropertyFilterProps.LoadItemsDetail>
  ) => {
    const { filteringProperty } = event.detail;

    if (!filteringProperty) return;

    setFilteringStatusType("loading");
    setFilteringOptions([]);

    await fetchSuggestions(event.detail);
  };

  const fetchSuggestions = async (
    detail: PropertyFilterProps.LoadItemsDetail
  ) => {
    if (detail.filteringProperty) {
      try {
        const extractedList = await adminRepo.fetchBusinessUnitSuggestions(
          detail,
          searchQuery
        );
        setFilteringStatusType("finished");

        if (extractedList) setFilteringOptions(extractedList);
      } catch (e: any) {
        console.error("Failed to fetch suggestions", e);
      }
    }
  };

  const handleSortingChange = (detail: TableProps.SortingState<any>) => {
    setSortingColumn(detail.sortingColumn);
    setSortingDescending(detail.isDescending);
    refresh();
  };

  return (
    <TaCSTableCloudscape
      tableHeading={`${BUSINESS_PROPERTY.BUSINESS_UNIT}s`}
      filteringPlaceholder={`Search for ${BUSINESS_PROPERTY.BUSINESS_UNIT}s`}
      businessProperty="businessUnits"
      primaryActionLabel={`Create ${BUSINESS_PROPERTY.BUSINESS_UNIT}`}
      isLoading={loadingTableData}
      data={tableData}
      serverError={serverError}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      tablePreferences={tablePreferences}
      setTablePreferences={setTablePreferences}
      columnDefinitions={BUSINESS_UNITS_TABLE_COLUMNS}
      items={tableData?.items}
      visibleContentPreferenceOptions={VISIBLE_CONTENT_OPTION_PREFERENCE_BU}
      selectedItems={selectedItems}
      setSelectedItems={setSelectedItems}
      handleTablePrimaryAction={handleOnCreate}
      handleTableActions={handleBUTableActions}
      refetch={refresh}
      itemId={ITEM_UNIQUE_ID.BUSINESS_UNIT}
      idToken={getIdToken}
      customAttributes={null}
      selectedBG={selectedBG}
      searchFilterProps={{
        filteringProperties: BU_FILTERING_PROPERTIES,
        searchQuery: searchQuery,
        handleSearchChange: handleSearchChange,
        filteringStatusType: filteringStatusType,
        filteringOptions: filteringOptions,
        onLoadItems: handleSearchOnLoadItems,
        items: { items: [], page: data.page },
      }}
      sortingColumn={sortingColumn}
      sortingDescending={sortingDescending}
      onSortingChange={({ detail }) => {
        handleSortingChange(detail);
      }}
    />
  );
};

export const BusinessUnitsDashboard: React.FC = () => {
  const { getIdToken, selectedBG } = useContext(AuthContext);

  return <BusinessUnits getIdToken={getIdToken} selectedBG={selectedBG} />;
};
