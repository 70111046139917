import { PropertyFilterProps } from "@cloudscape-design/components";
import { buildBasicSearchQuery, buildSuggestionsQuery } from "../opensearch/opensearchQueryUtils";
import { ReturnType } from "../client/client";
import { ENTITY_TYPE } from "../data/constants/common";
import { useFetchOpensearchData } from "../client/opensearchClient";
import IAdminRepository from "../interfaces/IAdminRepository";
import { getEnvironmentVariables } from "../data/constants/environment";

function createAdminRepository(getToken: () => string): IAdminRepository {
  return {
    searchBusinessGroups(searchParams: PropertyFilterProps.Query, pageSize: number, page: number, sortingField?: string, sortingDescending?: boolean): ReturnType {

      const searchQuery = buildBasicSearchQuery(searchParams, pageSize, page, ENTITY_TYPE.MEDIA_ITEMS, undefined, sortingField, sortingDescending);
      const body = { index: "businessunitgroups", query: searchQuery };

      return useFetchOpensearchData({
        body: JSON.stringify(body), getTokenFn: getToken, currentPage: page, pageSize: pageSize
      });
    },

    async fetchBusinessGroupSuggestions(itemDetail: PropertyFilterProps.LoadItemsDetail, searchParams: PropertyFilterProps.Query): Promise<PropertyFilterProps.FilteringOption[]> {
      if (itemDetail.filteringProperty?.key?.toLocaleLowerCase()?.includes("date")) {
        return [];
      }
      const token = await getToken();
      const url = getEnvironmentVariables().SEARCH_SERVICE_ENDPOINT;
      const query = buildSuggestionsQuery(itemDetail, searchParams, ENTITY_TYPE.MEDIA_ITEMS);

      const body = { index: "businessunitgroups", query: query };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) throw new Error("Failed to get suggestions");

      const data = await response.json();

      // Extract suggestions and ensure each field name is extracted once per hit
      const suggestions = data.search_results.hits.hits.map((hit: any) => {
        const keys = Object.keys(hit._source);
        return keys.length > 0 && itemDetail?.filteringProperty?.key ? hit._source[itemDetail.filteringProperty.key] : undefined;
      }).filter(Boolean); // Remove undefined entries if any

      // Map the unique suggestions to the desired format
      const extractedList = suggestions.map((option: any) => ({
        propertyKey: itemDetail.filteringProperty?.key,
        value: option,
      } as PropertyFilterProps.FilteringOption));

      return extractedList;
    },

    searchBusinessUnits(searchParams: PropertyFilterProps.Query, pageSize: number, page: number, sortingField?: string, sortingDescending?: boolean): ReturnType {

      const searchQuery = buildBasicSearchQuery(searchParams, pageSize, page, ENTITY_TYPE.MEDIA_ITEMS, undefined, sortingField, sortingDescending);
      const body = { index: "businessunits", query: searchQuery };

      return useFetchOpensearchData({
        body: JSON.stringify(body), getTokenFn: getToken, currentPage: page, pageSize: pageSize
      });
    },

    async fetchBusinessUnitSuggestions(itemDetail: PropertyFilterProps.LoadItemsDetail, searchParams: PropertyFilterProps.Query): Promise<PropertyFilterProps.FilteringOption[]> {
      if (itemDetail.filteringProperty?.key?.toLocaleLowerCase()?.includes("date")) {
        return [];
      }
      const token = await getToken();
      const url = getEnvironmentVariables().SEARCH_SERVICE_ENDPOINT;
      const query = buildSuggestionsQuery(itemDetail, searchParams, ENTITY_TYPE.MEDIA_ITEMS);

      const body = { index: "businessunits", query: query };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) throw new Error("Failed to get suggestions");

      const data = await response.json();

      // Extract suggestions and ensure each field name is extracted once per hit
      const suggestions = data.search_results.hits.hits.map((hit: any) => {
        const keys = Object.keys(hit._source);
        return keys.length > 0 && itemDetail?.filteringProperty?.key ? hit._source[itemDetail.filteringProperty.key] : undefined;
      }).filter(Boolean); // Remove undefined entries if any

      // Map the unique suggestions to the desired format
      const extractedList = suggestions.map((option: any) => ({
        propertyKey: itemDetail.filteringProperty?.key,
        value: option,
      } as PropertyFilterProps.FilteringOption));

      return extractedList;
    },

    searchAttributes(searchParams: PropertyFilterProps.Query, pageSize: number, page: number, sortingField?: string, sortingDescending?: boolean): ReturnType {

      const searchQuery = buildBasicSearchQuery(searchParams, pageSize, page, ENTITY_TYPE.MEDIA_ITEMS, undefined, sortingField, sortingDescending);
      const body = { index: "attributes", query: searchQuery };

      return useFetchOpensearchData({
        body: JSON.stringify(body), getTokenFn: getToken, currentPage: page, pageSize: pageSize
      });
    },

    async fetchAttributeSuggestions(itemDetail: PropertyFilterProps.LoadItemsDetail, searchParams: PropertyFilterProps.Query): Promise<PropertyFilterProps.FilteringOption[]> {
      if (itemDetail.filteringProperty?.key?.toLocaleLowerCase()?.includes("date")) {
        return [];
      }
      const token = await getToken();
      const url = getEnvironmentVariables().SEARCH_SERVICE_ENDPOINT;
      const query = buildSuggestionsQuery(itemDetail, searchParams, ENTITY_TYPE.MEDIA_ITEMS);

      const body = { index: "attributes", query: query };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": token
        },
        body: JSON.stringify(body)
      });

      if (!response.ok) throw new Error("Failed to get suggestions");

      const data = await response.json();

      // Extract suggestions and ensure each field name is extracted once per hit
      const suggestions = data.search_results.hits.hits.map((hit: any) => {
        const keys = Object.keys(hit._source);
        return keys.length > 0 && itemDetail?.filteringProperty?.key ? hit._source[itemDetail.filteringProperty.key] : undefined;
      }).filter(Boolean); // Remove undefined entries if any

      // Map the unique suggestions to the desired format
      const extractedList = suggestions.map((option: any) => ({
        propertyKey: itemDetail.filteringProperty?.key,
        value: option,
      } as PropertyFilterProps.FilteringOption));

      return extractedList;
    },


  };
}

export { createAdminRepository };
