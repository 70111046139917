import {
  ENTITY_TYPE,
  FLIGHTPLANS_END_DATE,
  FLIGHTPLANS_START_DATE,
  MEDIA_ITEM_END_DATE,
  MEDIA_ITEM_START_DATE,
} from "./../constants/common";
import {
  LOCALES_DEFAULT,
  NUMERIC_FORMAT,
  TWO_DIGIT_FORMAT,
  UTC,
  CUSTOM_ATTRIBUTE_BULK_PREFIX,
  CAMPAIGN_PLANNED_START_DATE,
  CAMPAIGN_PLANNED_END_DATE,
} from "../../data/constants/common";
import { formatDate } from "./dates";
import { ENVIRONMENT, getEnvironmentVariables } from "../constants/environment";
import { AwsRum } from "aws-rum-web";
import { Auth } from "aws-amplify";
import { RUM_CONFIG } from "../constants/rumConfig";
import { Attribute } from "../../views/admin/Interface";
import { BULKJOB_HEADERS } from "../config/commonDashboardConfig";

export const convertToCSV = (
  input: any[],
  customAttributes: any,
  entityType: string
) => {
  // TODO: Fetch from campaign model API (backend not implemented yet)
  let headers: any;

  switch (entityType) {
  case ENTITY_TYPE.CAMPAIGNS:
    headers = BULKJOB_HEADERS[ENTITY_TYPE.CAMPAIGNS];
    break;
  case ENTITY_TYPE.FLIGHT_PLANS:
    headers = BULKJOB_HEADERS[ENTITY_TYPE.FLIGHT_PLANS];
    break;
  case ENTITY_TYPE.MEDIA_ITEMS:
    headers = BULKJOB_HEADERS[ENTITY_TYPE.MEDIA_ITEMS];
    break;
  case ENTITY_TYPE.MEDIA_ID_REQUEST:
    headers = BULKJOB_HEADERS[ENTITY_TYPE.MEDIA_ID_REQUEST];
    break;
  case "REQUEST_MEDIA_ID_RESPONSE":
    headers = BULKJOB_HEADERS["REQUEST_MEDIA_ID_RESPONSE"];
    break;
  case ENTITY_TYPE.USERS:
    headers = BULKJOB_HEADERS[ENTITY_TYPE.USERS];
  }

  customAttributes?.attributes.map((attribute: Attribute) => {
    if (
      entityType === ENTITY_TYPE.CAMPAIGNS ||
      entityType === ENTITY_TYPE.MEDIA_ITEMS
    ) {
      headers[CUSTOM_ATTRIBUTE_BULK_PREFIX + attribute?.slug] = "";
    }
  });

  const data: any[] = [];
  input.map((item) => {
    const tempHeaders = headerMapper(headers, entityType);
    Object.keys(tempHeaders).forEach((header: any) => {
      tempHeaders[header] = "";
      if (header == CAMPAIGN_PLANNED_START_DATE) {
        tempHeaders.campaignstartdateplanned = formatDate(item[header]);
      } else if (header == CAMPAIGN_PLANNED_END_DATE) {
        tempHeaders.campaignenddateplanned = formatDate(item[header]);
      } else if (header.includes(CUSTOM_ATTRIBUTE_BULK_PREFIX) && entityType === ENTITY_TYPE.MEDIA_ITEMS) {
        header = header.replace(CUSTOM_ATTRIBUTE_BULK_PREFIX, "");
        tempHeaders[CUSTOM_ATTRIBUTE_BULK_PREFIX + header] =
          item[CUSTOM_ATTRIBUTE_BULK_PREFIX + header] != undefined
            ? item[CUSTOM_ATTRIBUTE_BULK_PREFIX + header].toString()
            : "";
      } else if (header.includes(CUSTOM_ATTRIBUTE_BULK_PREFIX)) {
        header = header.replace(CUSTOM_ATTRIBUTE_BULK_PREFIX, "");
        tempHeaders[CUSTOM_ATTRIBUTE_BULK_PREFIX + header] =
          item[CUSTOM_ATTRIBUTE_BULK_PREFIX + header] != undefined
            ? item[CUSTOM_ATTRIBUTE_BULK_PREFIX + header].toString()
            : "";
      } else if (entityType === ENTITY_TYPE.MEDIA_ITEMS && header === MEDIA_ITEM_START_DATE) {
        tempHeaders[MEDIA_ITEM_START_DATE] = formatDate(item[header]);
      } else if (entityType === ENTITY_TYPE.MEDIA_ITEMS && header === MEDIA_ITEM_END_DATE) {
        tempHeaders[MEDIA_ITEM_END_DATE] = formatDate(item[header]);
      } else if (header === FLIGHTPLANS_START_DATE) {
        tempHeaders[FLIGHTPLANS_START_DATE] = formatDate(item[header]);
      } else if (header === FLIGHTPLANS_END_DATE) {
        tempHeaders[FLIGHTPLANS_END_DATE] = formatDate(item[header]);
      } else {
        tempHeaders[header] =
          item[header] == undefined
            ? tempHeaders[header]
            : /* eslint-disable */
            typeof item[header] === "object"
            ? '"[' + item[header].toString() + ']"'
            : typeof item[header] === "string"
            ? '"' + item[header].replace(/"/g, '""') + '"'
            : item[header].toString();
      }
    });
    data.push("\n" + Object.values(tempHeaders).toString());
  });
  return Object.keys(headers).toString() + data;
};

const headerMapper = (headers: any, entityType: string) => {
  let mappedHeaders = entityType === ENTITY_TYPE.FLIGHT_PLANS 
    ? BULKJOB_HEADERS["FLIGHT_PLAN_HEADER_MAPPER"]
    : Object.assign({}, headers);

  if (entityType === ENTITY_TYPE.MEDIA_ITEMS) {
    mappedHeaders = BULKJOB_HEADERS["MEDIA_ITEM_HEADER_MAPPER"]
    Object.keys(headers).map(header => {
      if (header.includes(CUSTOM_ATTRIBUTE_BULK_PREFIX)) {
        mappedHeaders[header] = "";
      }
    })
  }

  return mappedHeaders;
};

export const csvDownload = (
  input: any,
  customAttributes: any,
  fileName: string,
  entityType: string
): void => {
  const csv = convertToCSV(input, customAttributes, entityType);

  //encode # and other speical character in the encoded URI as well
  //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
  const encodedCsv = encodeURIComponent(csv);
  const encodedUri = `data:text/csv;charset=utf-8,${encodedCsv}`;

  const link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", fileName.concat(".csv"));
  document.body.appendChild(link); // Required for FF

  link.click(); // This will download the data file
};

export const generateTemplateDownloadCSVName = (
  entityType: string,
  businessGroupName: string,
  displayDate: boolean
): string => {
  let fileName = entityType.concat("_", businessGroupName);

  if (displayDate === true) {
    const date = new Date();
    const year = date.toLocaleString(LOCALES_DEFAULT, {
      year: NUMERIC_FORMAT,
      timeZone: UTC,
    });
    const month = date.toLocaleString(LOCALES_DEFAULT, {
      month: TWO_DIGIT_FORMAT,
      timeZone: UTC,
    });
    const day = date.toLocaleString(LOCALES_DEFAULT, {
      day: TWO_DIGIT_FORMAT,
      timeZone: UTC,
    });

    fileName = fileName.concat("_", year, month, day);
  }

  return fileName;
};

export async function initiateRumClient(): Promise<AwsRum | undefined> {
  const environmentVariables = getEnvironmentVariables();

  //To be removed when we deploy RUM to Production
  const { hostname } = window.location;
  if (hostname == ENVIRONMENT.PROD) {
    return;
  }

  let awsRum = null;
  try {
    const APPLICATION_ID = environmentVariables.RUM_APP_ID;
    const APPLICATION_VERSION = "1.0.0";
    const APPLICATION_REGION = environmentVariables.REGION;

    awsRum = new AwsRum(
      APPLICATION_ID,
      APPLICATION_VERSION,
      APPLICATION_REGION,
      RUM_CONFIG
    );

    const cred = await Auth.currentCredentials();
    awsRum.setAwsCredentials(cred);
    awsRum.enable();
    return awsRum;
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}
