import React, {
  FC,
  ReactElement,
  useState,
  useEffect,
  useMemo,
  useContext,
} from "react";
import {
  ButtonDropdown,
  ButtonDropdownProps,
  CollectionPreferences,
  Header,
  Button,
  ButtonProps,
  Box,
  Table,
  TableProps,
  SpaceBetween,
  NonCancelableCustomEvent,
  CollectionPreferencesProps,
  PropertyFilterProps,
  Hotspot,
} from "@cloudscape-design/components";
import { IFlightPlan, IPaginated } from "../../data/interfaces/ICampaign";
import { ITableSettings } from "../../data/interfaces/ITable";
import { getEnvironmentVariables } from "../../data/constants/environment";
import { DEFAULT_MESSAGE } from "../../data/constants/errorMessages";
import { returnErrorMessage } from "../../data/helpers/returnErrorMessages";
import {
  MessageContext,
  MessagesContextType,
} from "../../context/MessagingContext";
import { AuthContext } from "../../context/AuthContext";
import {
  csvDownload,
  generateTemplateDownloadCSVName,
} from "../../data/helpers/utils";
import styles from "./CloudscapeTable.module.css";
import ArchiveModal from "../../components/modals/ArchiveModal";
import CloudscapeTablePropertyFilter, {
  IPropertyFilterProps,
} from "./CloudscapeTablePropertyFilter";
import CloudscapeTablePagination from "./Pagination";
import {
  getSavedPreferencesFromLocalStorage,
  getSavedSearchFiltersLocalStorage,
} from "./TableUtils";
import {
  API_CALL_NAME,
  ENTITY_TYPE,
  ITEM_UNIQUE_ID,
  capitalize,
  getPageName,
} from "../../data/constants/common";
import { isAuthorizedActionForExternalUser } from "../../data/helpers/featureGate";
import { useLocation } from "react-router-dom";
import { getHotspotByPage } from "../tutorials/Tutorials";
import { DropdownStatusProps } from "@cloudscape-design/components/internal/components/dropdown-status";
import { RepositoryContext } from "../../context/RepositoryContext";
import {
  hasWriteAccessOnSelectedItems,
  isArrayBSubsetOfArrayA,
} from "../../data/helpers/functions";
import { SelectAllHandler } from "./SelectAllHandler";
import { bulkDownloadAllItems } from "../../client/bulkExportClient";
import { handleSavedSearchFilters } from "../../data/config/commonDashboardConfig";

export interface ICloudscapeTableProps
  extends Omit<
    TableProps,
    | "items"
    | "loading"
    | "selectedItems"
    | "filter"
    | "pagination"
    | "visibleColumns"
    | "wrapLines"
    | "preferences"
    | "onSortingChange"
    | "selectionType"
  > {
  actionHandlers: {
    handleOnEdit?: (entity: any) => void;
    hasArchiveButton?: boolean;
  };
  handleOnCreate: (event: CustomEvent<ButtonProps.ClickDetail>) => void;
  resourceName: string;
  apiNameCall: string;
  title: string;
  selectionType?: TableProps.SelectionType | undefined;
  visibleContentDefault: ReadonlyArray<string>;
  visibleContentOptions: CollectionPreferencesProps.VisibleContentOption[];
  filteringProperties: PropertyFilterProps.FilteringProperty[];
  savedPreferences: ITableSettings;
  entityType: string;
  savedSearchFilters: PropertyFilterProps.Query;
}

const CloudscapeTable: FC<ICloudscapeTableProps> = (
  props: ICloudscapeTableProps
): ReactElement | null => {
  const {
    actionHandlers,
    handleOnCreate,
    title,
    columnDefinitions,
    resourceName,
    visibleContentDefault,
    visibleContentOptions,
    filteringProperties,
    savedPreferences,
    entityType,
    savedSearchFilters,
    ...tableProps
  } = props;
  const { addMessage } = useContext(MessageContext) as MessagesContextType;
  const { campaignRepo, flightPlanRepo } = useContext(RepositoryContext);
  const {
    getIdToken,
    selectedBG,
    customCampaignAttributes: customAttributes,
    isExternal,
  } = useContext(AuthContext);
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] =
    useState<PropertyFilterProps.Query>(savedSearchFilters);
  const [selectedItems, setSelectedEntities] = useState<any[]>([]);
  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences | any
  >(savedPreferences);
  const [entities, setEntities] = useState<IPaginated>({
    items: [],
    page: {
      number: 0,
      numberOfElements: 0,
      size: 0,
      totalElements: 0,
      totalPages: 0,
    },
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isArchiveModalVisible, setArchiveModalVisible] =
    useState<boolean>(false);
  const [filteringStatusType, setFilteringStatusType] =
    useState<DropdownStatusProps.StatusType>("finished");
  const [filteringOptions, setFilteringOptions] = useState<
    PropertyFilterProps.FilteringOption[]
  >([]);
  const [sortingDescending, setSortingDescending] = useState<
    boolean | undefined
  >(true);
  const [sortingColumn, setSortingColumn] =
    useState<TableProps.SortingColumn<any>>();
  const [allPageItemsSelected, setAllPageItemsSelected] = useState<boolean>(
    isArrayBSubsetOfArrayA(selectedItems, entities.items)
  );
  const [allItemsSelected, setAllItemsSelected] = useState<boolean>(false);
  const page = entities?.page;

  useEffect(() => {
    setSelectedEntities(selectedItems);
    setAllPageItemsSelected(
      isArrayBSubsetOfArrayA(selectedItems, entities.items)
    );
  }, [selectedItems]);

  useEffect(() => {
    setSelectedEntities([]);
    setAllItemsSelected(false);
  }, [page?.totalElements]);

  useEffect(() => {
    handleSavedSearchFilters({
      query: searchQuery,
      businessGroup: selectedBG?.businessGroupSlug,
      entityType: entityType,
    });
  }, [searchQuery]);

  const entityName =
    entityType === ENTITY_TYPE.CAMPAIGNS ? "Campaign" : "Flight plan";

  const handleSearchChange = (query: PropertyFilterProps.Query) => {
    setSearchQuery(query);
    setCurrentPage(1);
  };

  //https://cloudscape.design/components/property-filter?tabId=api
  const handleSearchOnLoadItems = async (
    event: NonCancelableCustomEvent<PropertyFilterProps.LoadItemsDetail>
  ) => {
    const { filteringProperty } = event.detail;

    if (!filteringProperty) return;

    setFilteringStatusType("loading");
    setFilteringOptions([]);

    await fetchSuggestions(event.detail);
  };

  const fetchData = async (query: PropertyFilterProps.Query) => {
    setIsLoading(true);

    const data =
      entityType === ENTITY_TYPE.CAMPAIGNS
        ? await campaignRepo?.searchCampaigns(
          query,
          preferences.pageSize,
          currentPage,
          sortingColumn?.sortingField,
          sortingDescending
        )
        : await flightPlanRepo?.searchFlightPlans(
          query,
          preferences.pageSize,
          currentPage,
          sortingColumn?.sortingField,
          sortingDescending
        );

    if (data) {
      setEntities(data);
      setIsLoading(false);
      return;
    }
  };

  const fetchSuggestions = async (
    detail: PropertyFilterProps.LoadItemsDetail
  ) => {
    if (detail.filteringProperty) {
      try {
        const extractedList =
          entityType === ENTITY_TYPE.CAMPAIGNS
            ? await campaignRepo?.fetchCampaignSuggestions(detail, searchQuery)
            : await flightPlanRepo?.fetchFlightPlanSuggestions(detail, searchQuery);
        setFilteringStatusType("finished");

        if (extractedList) setFilteringOptions(extractedList);
      } catch (e: any) {
        addMessage(
          {
            header: "Failed to load resources",
            type: "error",
            content: DEFAULT_MESSAGE.message,
            dismissible: true,
          },
          e.message
        );
      }
    }
  };

  useEffect(() => {
    setPreferences(
      getSavedPreferencesFromLocalStorage(resourceName, visibleContentDefault)
    );

    if (selectedBG?.businessUnits != null) {
      fetchData(searchQuery);
    }
    if (selectedItems.length === 0) {
      setAllItemsSelected(false);
    }
  }, [preferences.pageSize, currentPage, searchQuery, selectedBG]);

  useEffect(() => {
    setSelectedEntities([]);
    setCurrentPage(1);
    setSearchQuery(getSavedSearchFiltersLocalStorage(entityType));
  }, [selectedBG]);

  const actionItems: ButtonDropdownProps.Item[] = useMemo(() => {
    const items: ButtonDropdownProps.Item[] = [];
    if (actionHandlers?.handleOnEdit) {
      items.push({
        id: "edit",
        text: "Edit",
        disabled:
          !hasWriteAccessOnSelectedItems(selectedItems) ||
          !actionHandlers?.handleOnEdit ||
          selectedItems.length != 1,
        description: `Edit ${resourceName}`,
      });
    }
    if (actionHandlers?.hasArchiveButton) {
      items.push({
        id: "archive",
        text: "Archive",
        disabled:
          !hasWriteAccessOnSelectedItems(selectedItems) ||
          allItemsSelected ||
          !selectedItems.length,
        description: `Archive ${resourceName}`,
      });
    }
    return items;
  }, [actionHandlers, resourceName, selectedItems, allItemsSelected]);

  const handleActionButtonClick = (
    event: NonCancelableCustomEvent<ButtonDropdownProps.ItemClickDetails>
  ): void => {
    if (!actionHandlers) return;

    const {
      detail: { id },
    } = event;
    const { handleOnEdit } = actionHandlers;

    switch (id) {
    case "edit":
      if (handleOnEdit) handleOnEdit(selectedItems[0]);
      break;
    case "archive":
      if (handleOnArchive) handleOnArchive();
      break;
    }
  };

  const handleSavePreferences = (
    event: NonCancelableCustomEvent<CollectionPreferencesProps.Preferences>
  ): void => {
    const { detail } = event;
    setPreferences(detail);
    setCurrentPage(1);

    const currentPreferences: string | null = window.localStorage.getItem(
      `${resourceName}Table`
    );
    let parsedPreferences =
      currentPreferences !== null && currentPreferences !== undefined
        ? JSON.parse(currentPreferences)
        : {};
    const currBusinessGroup: any = selectedBG?.businessGroupSlug;

    if (parsedPreferences === null) {
      parsedPreferences = {
        [currBusinessGroup]: detail,
      };
    }
    if (currBusinessGroup) parsedPreferences[currBusinessGroup] = detail;
    localStorage.setItem(
      `${resourceName}Table`,
      JSON.stringify(parsedPreferences)
    );
  };

  const shouldDisableActionsButtton = () => {
    if (selectedItems.length === 0) {
      return true;
    }

    if (
      selectedBG &&
      selectedBG?.businessUnits[selectedItems[0]?.businessunitSlug] &&
      selectedBG?.businessUnits[selectedItems[0]?.businessunitSlug]
        .isAccessReadOnly
    ) {
      return true;
    }

    return false;
  };

  const shouldDisableCreateButton = () => {
    // If the user has at least 1BU that they can write to within the selected BG
    // The 'Create' button will not be disabled
    if (selectedBG) {
      for (const key of Object.keys(selectedBG?.businessUnits)) {
        if (selectedBG?.businessUnits[key].isAccessReadOnly == false) {
          return false;
        }
      }
    }

    return true;
  };

  const handleOnArchive = (): void => {
    setArchiveModalVisible(true);
  };

  const handleOnClickArchive = async (archiveableEntities: any[]) => {
    setArchiveModalVisible(false);
    await postArchiveEntities(archiveableEntities);
  };

  const postArchiveEntities = async (entities: any[]) => {
    let endpoint,
      listObject = "";
    switch (entityType) {
    case ENTITY_TYPE.CAMPAIGNS:
      endpoint = "campaigns";
      listObject = "tacsidList";
      break;
    case ENTITY_TYPE.FLIGHT_PLANS:
      endpoint = "flightplans";
      listObject = "flightPlanIdList";
      break;
    }

    const bus = entities.map(
      (item) => item?.businessUnitSlug || item?.businessunitSlug
    );

    const idList = entities.map((item) => item?.id || item?.tacsid);
    const url = `${
      getEnvironmentVariables().API_ENDPOINT
    }/archive/${endpoint}?${encodeURI(bus.map((bu) => `bu=${bu}`).join("&"))}`;

    try {
      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
        method: "POST",
        body: JSON.stringify({ [listObject]: idList }),
      });

      if (response.ok) {
        addMessage({
          type: "success",
          content: `${entityName}s archived successfully!`,
          dismissible: true,
        });
        fetchData(searchQuery);
      } else {
        addMessage({
          type: "error",
          content: `Failed to archive ${entityName.toLocaleLowerCase()}s. Please try again later.`,
          dismissible: true,
        });
        returnErrorMessage(response.status).message;
      }
    } catch (error) {
      addMessage({
        type: "error",
        content:
          `Failed to archive ${entityName.toLocaleLowerCase()}s. Please try again later. ` +
          error,
        dismissible: true,
      });
    }
    setSelectedEntities([]);
  };

  const updateFlightPlan = async (flightPlan: IFlightPlan, newName: string) => {
    const url = `${getEnvironmentVariables().API_ENDPOINT}/${
      API_CALL_NAME.FLIGHT_PLANS
    }/${flightPlan.businessUnitSlug}/${flightPlan.id}`;
    try {
      const response = await fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: await getIdToken(),
        },
        body: JSON.stringify({
          ...flightPlan,
          name: newName,
        }),
      });

      if (response.ok) {
        return true;
      } else {
        addMessage({
          type: "error",
          content: "Failed to update name",
          dismissible: true,
        });
      }
    } catch (error) {
      addMessage({
        type: "error",
        content: "Failed to update name",
        dismissible: true,
      });
    }
  };

  const submitEdit = async (currentItem: any, column: any, value: any) => {
    if (value) {
      const response = await updateFlightPlan(currentItem, value);
      const newItem = { ...currentItem, [column.id]: value };

      if (response) {
        setEntities({
          ...entities,
          items: entities.items.map((item) =>
            item === currentItem ? newItem : item
          ),
        });
      }
    } else {
      addMessage({
        type: "error",
        content: "Enter valid flight name",
        dismissible: true,
      });
    }
  };

  const handleSortingChange = (detail: TableProps.SortingState<any>) => {
    setSortingColumn(detail.sortingColumn);
    setSortingDescending(detail.isDescending);
  };
  useEffect(() => {
    fetchData(searchQuery); // Fetches with the updated sorting criteria
  }, [sortingDescending]);

  return (
    <div>
      <Table
        data-testid={`${resourceName}-cloudscape-table`}
        variant="full-page"
        sortingColumn={sortingColumn}
        sortingDescending={sortingDescending}
        onSortingChange={({ detail }) => {
          handleSortingChange(detail);
        }}
        header={
          <Header
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  ariaLabel="refresh"
                  iconName="refresh"
                  onClick={() => fetchData(searchQuery)}
                  data-testid="awsui-refresh"
                />

                {actionItems.length != 0 && (
                  <div className={styles.relative}>
                    <div className={styles.tooltipOnHover}>
                      <ButtonDropdown
                        data-testid="awsui-button-actions"
                        disabled={
                          shouldDisableActionsButtton() ||
                          isAuthorizedActionForExternalUser(
                            entityType,
                            isExternal
                          )
                        }
                        items={actionItems}
                        onItemClick={handleActionButtonClick}
                      >
                        Actions
                      </ButtonDropdown>
                    </div>
                  </div>
                )}

                {
                  <Button
                    data-testid="handle-bulk-download"
                    disabled={selectedItems.length < 1}
                    onClick={() => {
                      if (allItemsSelected) {
                        bulkDownloadAllItems(
                          getIdToken,
                          allItemsSelected,
                          entityType === ENTITY_TYPE.CAMPAIGNS
                            ? API_CALL_NAME.CAMPAIGNS
                            : API_CALL_NAME.FLIGHT_PLANS,
                          customAttributes,
                          {
                            searchQuery,
                            items: entities,
                          } as IPropertyFilterProps,
                          selectedBG
                        );
                      } else {
                        csvDownload(
                          selectedItems,
                          customAttributes,
                          generateTemplateDownloadCSVName(
                            entityType,
                            selectedBG?.businessGroupName || "campaigns",
                            true
                          ),
                          entityType
                        );
                      }
                      setSelectedEntities([]);
                    }}
                  >
                    Bulk download {capitalize(resourceName)}s
                  </Button>
                }
                <Hotspot
                  hotspotId={
                    getHotspotByPage(getPageName(pathname)).createNewItem
                  }
                  side="right"
                  direction="bottom"
                >
                  <Button
                    data-testid="awsui-button-primary"
                    disabled={
                      shouldDisableCreateButton() ||
                      isAuthorizedActionForExternalUser(entityType, isExternal)
                    }
                    onClick={handleOnCreate}
                    variant="primary"
                  >
                    Create {capitalize(resourceName)}
                  </Button>
                </Hotspot>
              </SpaceBetween>
            }
          >
            {capitalize(title)}s <>in {selectedBG?.businessGroupName}</>
          </Header>
        }
        {...tableProps}
        filter={
          <>
            <Hotspot
              hotspotId={getHotspotByPage(getPageName(pathname)).filterItems}
              side="left"
              direction="bottom"
            >
              <CloudscapeTablePropertyFilter
                filteringProperties={filteringProperties}
                handleSearchChange={handleSearchChange}
                items={entities}
                searchQuery={searchQuery}
                onLoadItems={handleSearchOnLoadItems}
                filteringStatusType={filteringStatusType}
                filteringOptions={filteringOptions}
              />
            </Hotspot>
            {page?.totalPages > 1 && (
              <SelectAllHandler
                page={page}
                setSelectedItems={setSelectedEntities}
                setAllItemsSelected={setAllItemsSelected}
                allPageItemsSelected={allPageItemsSelected}
                allItemsSelected={allItemsSelected}
              />
            )}
          </>
        }
        trackBy={(item) =>
          entityType === ENTITY_TYPE.CAMPAIGNS
            ? item[ITEM_UNIQUE_ID.CAMPAIGN]
            : item[ITEM_UNIQUE_ID.FLIGHT_PLAN]
        }
        items={entities?.items}
        loading={isLoading}
        onSelectionChange={({ detail }) =>
          setSelectedEntities(detail.selectedItems)
        }
        selectedItems={selectedItems}
        pagination={
          <CloudscapeTablePagination
            items={entities}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        }
        preferences={
          <Hotspot
            hotspotId={
              getHotspotByPage(getPageName(pathname)).changeDashboardSettings
            }
            side="right"
            direction="bottom"
          >
            <CollectionPreferences
              title="Display Preferences"
              data-testid="collection-preferences"
              confirmLabel="Apply and Save Settings"
              cancelLabel="Cancel"
              onConfirm={handleSavePreferences}
              preferences={preferences}
              pageSizePreference={{
                title: "Items per page",
                options: [
                  { value: 5, label: "5" },
                  { value: 10, label: "10" },
                  { value: 25, label: "25" },
                  { value: 50, label: "50" },
                ],
              }}
              visibleContentPreference={{
                title: "Select visible columns",
                options: [
                  {
                    label: "",
                    options: visibleContentOptions,
                  },
                ],
              }}
            />
          </Hotspot>
        }
        visibleColumns={preferences.visibleContent}
        wrapLines={preferences.wrapLines}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No {capitalize(resourceName)}s</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No {capitalize(resourceName)}s to display.
            </Box>
          </Box>
        }
        columnDefinitions={columnDefinitions}
        submitEdit={submitEdit}
        ariaLabels={{
          selectionGroupLabel: "Items selection",
          allItemsSelectionLabel: ({ selectedItems }) =>
            `${selectedItems.length} ${
              selectedItems.length === 1 ? "item" : "items"
            } selected`,
          itemSelectionLabel: ({ selectedItems }, item: any) => {
            const isItemSelected = selectedItems.filter(
              (i: any) => i.name === item.name
            ).length;
            return `${item.name} is ${isItemSelected ? "" : "not"} selected`;
          },
        }}
      />
      <ArchiveModal
        isVisible={isArchiveModalVisible}
        setVisible={setArchiveModalVisible}
        entities={selectedItems}
        handleOnClickArchive={handleOnClickArchive}
        entityType={entityType}
      />
    </div>
  );
};

export default CloudscapeTable;
